<template>
    <div class="login-container">
        <div class="user-form-wrapper">
            <div class="logo-container">    
                <a href="/">
                    <img :src="require('@/assets/logo-mobile-header.svg')" alt="Logo" class="sidebar__top__logo">
                </a>
            </div>
            <div class="logon-title">{{ labels.forgot_page_description }}</div>

            <FormulateForm @submit="forgotPass">
                <FormulateInput
                type="email"
                name="email"
                :placeholder="labels.your_email"
                validation="required"
                error-behavior="blur"
                />

                <div class="button-wrapper">
                    
                    <div class="text-center">
                        <router-link
                            class="form-pass-restore password-reset-link"
                            to="/login"
                            :title="labels.back_to_login"
                        >
                            {{ labels.back_to_login }}
                        </router-link>
                    </div>

                    <FormulateInput type="submit" :label="labels.reset_password" />
                </div>

            </FormulateForm>

        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
	computed: {
        ...mapGetters('helper', ['labels'])
    },
    methods: {
        ...mapActions( 'user' , {
			forgot: 'forgot'
		} ),
        forgotPass( data ) {
            this.forgot( data )
                .then( res => {
                    if ( res.success ) {
                        this.addNotification( { variant: 'success', msg: res.message, labels: this.labels } );
                    } else if ( res.error ) {
                        this.addNotification( { variant: 'danger', msg: res.error, labels: this.labels } );
                    }
                } )
                .catch( err => console.log(err) );
        },
    }
}
</script>

<style lang="scss" scoped>
    @import '@/styles/multi-component/user-logon';
    .user-form-wrapper{
        .logon-title{
            text-align:left;
            margin-bottom:20px;        }
        .button-wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            a {
                color: #5b5b5b;
                font-size: 14px;
            }
            div[data-type="submit"]{
                margin-bottom:0
            }
        }
    }
</style>